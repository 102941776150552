/* You can add global styles to this file, and also import other style files */
a { cursor: pointer; }

.app-container {
    min-height: 320px;
    overflow: hidden;
}

.btn-delete-user {
    width: 40px;
    text-align: center;
    box-sizing: content-box;
}