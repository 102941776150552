

html,
body {
	height: 100%;
	font-family: 'Raleway', sans-serif;
	-webkit-font-smoothing: antialiased;
	font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	font-weight: 600;
	color: #474747;
}

h1 {
	font-size: 70px;
}

h2 {
	font-size: 36px;
}

h3 {
	font-size: 30px;
}

h4 {
	font-size: 24px;
}

h5 {
	font-size: 20px;
}

h6 {
	font-size: 16px;
}

p {
	font-size: 14.1px;
	color: #878787;
	line-height: 2.2;
	font-weight: 500;
}

img {
	max-width: 100%;
}

input:focus,
select:focus,
button:focus,
textarea:focus {
	outline: none;
}

::-webkit-input-placeholder {
	font-style: italic;
}

::-ms-input-placeholder {
	font-style: italic;
}

:-ms-input-placeholder {
	font-style: italic;
}

::placeholder {
	font-style: italic;
}

a:hover {
	color: #d82a4e;
}

a:hover,
a:focus {
	text-decoration: none;
	outline: none;
}

ul,
ol {
	padding: 0;
	margin: 0;
}

/*---------------------
  Helper CSS
-----------------------*/

.section-title {
	text-align: center;
	padding: 0 110px;
}

.section-title h2 {
	font-size: 48px;
	font-weight: 600;
	margin-bottom: 25px;
}

.section-title p {
	margin-bottom: 0;
	font-weight: 500;
}

.set-bg {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: top center;
}

.spad {
	padding-top: 115px;
	padding-bottom: 115px;
}

.text-white h1,
.text-white h2,
.text-white h3,
.text-white h4,
.text-white h5,
.text-white h6,
.text-white p,
.text-white span,
.text-white li,
.text-white a {
	color: #fff;
}

.rating i {
	color: #fbb710;
}

.rating .is-fade {
	color: #e0e3e4;
}

/*---------------------
  Commom elements
-----------------------*/

/* buttons */

.site-btn {
	display: inline-block;
	min-width: 196px;
	text-align: center;
	border: none;
	padding: 15px 10px;
	font-weight: 600;
	font-size: 16px;
	position: relative;
	color: #fff;
	cursor: pointer;
	background: #d82a4e;
}

.site-btn:hover {
	color: #fff;
}

.site-btn.btn-dark {
	background: #000;
}

.site-btn.btn-fade {
	background: #e4edef;
	color: #1f1f1f;
}

/* Preloder */

#preloder {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 999999;
	background: #fff;
}

.loader {
	width: 40px;
	height: 40px;
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -13px;
	margin-left: -13px;
	border-radius: 60px;
	animation: loader 0.8s linear infinite;
	-webkit-animation: loader 0.8s linear infinite;
}

@keyframes loader {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
		border: 4px solid #f44336;
		border-left-color: transparent;
	}
	50% {
		-webkit-transform: rotate(180deg);
		transform: rotate(180deg);
		border: 4px solid #673ab7;
		border-left-color: transparent;
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
		border: 4px solid #f44336;
		border-left-color: transparent;
	}
}

@-webkit-keyframes loader {
	0% {
		-webkit-transform: rotate(0deg);
		border: 4px solid #f44336;
		border-left-color: transparent;
	}
	50% {
		-webkit-transform: rotate(180deg);
		border: 4px solid #673ab7;
		border-left-color: transparent;
	}
	100% {
		-webkit-transform: rotate(360deg);
		border: 4px solid #f44336;
		border-left-color: transparent;
	}
}

/*------------------
  Header section
---------------------*/

.header-section {
	position: absolute;
	width: 100%;
	top: 0;
	left: 0;
	padding-top: 60px;
}

.site-logo {
	display: inline-block;
}

.main-menu ul {
	list-style: none;
}

.main-menu ul li {
	display: inline;
}

.main-menu ul li a {
	display: inline-block;
	font-size: 16px;
	color: #fff;
	margin-left: 45px;
	font-weight: 600;
	padding: 20px 0 5px;
}

.main-menu ul li a:hover {
	color: #d82a4e;
}

.header-btn {
	float: right;
	margin-right: 0;
}

.nav-switch {
	display: none;
}

/*------------------
  Hero Section
---------------------*/

.hero-section {
	height: 948px;
}

.hero-text {
	text-align: center;
	padding-top: 340px;
	margin-bottom: 105px;
}

.hero-text h2 {
	font-size: 60px;
	font-weight: 500;
	margin-bottom: 20px;
}

.intro-newslatter input[type=text],
.intro-newslatter input[type=email] {
	height: 53px;
	width: 50.4%;
	padding: 0 30px;
	margin-right: 22px;
	float: left;
	border: none;
	font-size: 14px;
}

.intro-newslatter .site-btn {
	min-width: 214px;
}

/*------------------
  Categories section
---------------------*/

.categories-section .container {
	margin-bottom: -34px;
}

.categorie-item {
	margin-bottom: 34px;
	background: #edf4f6;
	-webkit-transition: all 0.4s;
	-o-transition: all 0.4s;
	transition: all 0.4s;
}

.categorie-item .ci-thumb {
	background-size: cover;
	height: 148px;
	-webkit-transition: all 0.2s;
	-o-transition: all 0.2s;
	transition: all 0.2s;
}

.categorie-item .ci-text {
	padding: 40px 35px;
}

.categorie-item .ci-text h5 {
	margin-bottom: 5px;
}

.categorie-item .ci-text span {
	font-size: 18px;
	font-weight: 600;
	color: #d82a4e;
}

.categorie-item:hover {
	background: #d82a4e;
}

.categorie-item:hover .ci-text h5,
.categorie-item:hover .ci-text p,
.categorie-item:hover .ci-text span {
	color: #fff;
}

/*------------------
  Search section
---------------------*/

.search-warp {
	background: #d82a4e;
	padding-top: 60px;
	padding-bottom: 50px;
}

.search-warp .section-title {
	margin-bottom: 30px;
}

.course-search-form {
	background: #fff;
	padding: 44px 38px;
}

.course-search-form input[type=text],
.course-search-form input[type=email] {
	height: 56px;
	width: 36.6%;
	padding: 0 30px;
	margin-right: 18px;
	float: left;
	border: none;
	font-size: 14px;
	background: #edf4f6;
}

.course-search-form input[type=text]:focus,
.course-search-form input[type=email]:focus {
	border-bottom: 2px solid #d82a4e;
}

.course-search-form .site-btn {
	/*min-width: 198px;*/
	padding: 16px 10px;
}

.search-section.ss-other-page {
	margin-top: -85px;
}

.search-section.ss-other-page .search-warp {
	position: relative;
	padding: 60px 0;
}

.search-section.ss-other-page .section-title {
	margin-bottom: 0;
}

.search-section.ss-other-page .section-title h2 {
	position: absolute;
	width: 100%;
	left: 0;
	bottom: 100%;
	font-size: 24px;
	margin-bottom: 0;
	text-align: center;
}

.search-section.ss-other-page .section-title h2 span {
	display: inline-block;
	background: #d82a4e;
	padding: 20px 60px 10px;
}

.search-section.ss-other-page .course-search-form {
	background-color: transparent;
	padding: 0;
}

.search-section.ss-other-page .course-search-form input[type=text]:focus,
.search-section.ss-other-page .course-search-form input[type=email]:focus {
	border-bottom: none;
}

/*------------------
  Course section
---------------------*/

.course-warp {
	max-width: 1559px;
	padding: 0 15px;
	margin: 0 auto -30px;
}

.course-item {
	margin-bottom: 30px;
}

.course-item .course-thumb {
	height: 270px;
}

.course-item .course-thumb .price {
	font-size: 14px;
	color: #fff;
	font-weight: 600;
	background: #d82a4e;
	display: inline-block;
	padding: 13px 19px;
	margin: 14px;
}

.course-item .course-info {
	background: #edf4f6;
}

.course-item .course-info .course-text {
	padding: 45px 33px 25px;
}

.course-item .course-info .course-text h5 {
	margin-bottom: 5px;
}

.course-item .course-info .course-text .students {
	font-size: 18px;
	font-weight: 600;
	color: #d82a4e;
}

.course-item .course-info .course-author {
	border-top: 1px solid #d4dee1;
	padding: 14px 33px;
	overflow: hidden;
}

.course-item .course-info .course-author .ca-pic {
	width: 44px;
	height: 44px;
	border-radius: 50%;
	float: left;
}

.course-item .course-info .course-author p {
	padding-left: 60px;
	margin-bottom: 0;
	padding-top: 7px;
	font-weight: 600;
}

.course-item .course-info .course-author p span {
	color: #d82a4e;
}

.course-item.featured-course {
	position: relative;
	margin-bottom: 97px;
}

.course-item.featured-course:last-child {
	margin-bottom: 30px;
}

.course-item.featured-course:nth-child(2n) .course-thumb {
	left: auto;
	right: 0;
}

.course-item.featured-course .course-thumb {
	position: absolute;
	width: 50%;
	height: 100%;
	left: 0;
	top: 0;
}

.course-item.featured-course .fet-note {
	display: inline-block;
	background: #d82a4e;
	color: #fff;
	font-size: 14px;
	font-weight: 500;
	padding: 4px 15px;
	margin-bottom: 20px;
}

.course-item.featured-course .course-info .course-text {
	padding: 70px 33px 25px;
}

.course-item.featured-course .course-info .course-text h5 {
	margin-bottom: 20px;
}

.course-item.featured-course .course-info .course-author {
	border-top: none;
	padding: 14px 33px 60px;
}

.course-filter {
	list-style: none;
	text-align: center;
	padding-top: 50px;
	margin-bottom: 60px;
}

.course-filter li {
	display: inline-block;
	font-size: 18px;
	font-weight: 600;
	color: #474747;
	padding: 12px 10px 5px;
	margin: 0 10px;
	border-bottom: 4px solid transparent;
	cursor: pointer;
	-webkit-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
}

.course-filter li.mixitup-control-active {
	color: #d82a4e;
	border-bottom: 4px solid #d82a4e;
}

/*------------------
  Signup section
---------------------*/

.signup-section {
	background: #d82a4e;
	position: relative;
}

.signup-section .section-title {
	padding: 0;
	margin-bottom: 55px;
}

.signup-section .signup-bg {
	position: absolute;
	width: 50%;
	height: 100%;
	right: 0;
	top: 0;
	z-index: 1;
}

.signup-warp {
	padding: 0 100px;
}

.signup-form,
.contact-form {
	background: #fff;
	padding: 60px;
}

.signup-form input[type=text],
.signup-form input[type=email],
.signup-form textarea,
.contact-form input[type=text],
.contact-form input[type=email],
.contact-form textarea {
	height: 57px;
	width: 100%;
	padding: 0 30px;
	margin-bottom: 27px;
	float: left;
	border: none;
	font-size: 14px;
	font-weight: 500;
	background: #edf4f6;
}

.signup-form input[type=text]:focus,
.signup-form input[type=email]:focus,
.signup-form textarea:focus,
.contact-form input[type=text]:focus,
.contact-form input[type=email]:focus,
.contact-form textarea:focus {
	border-bottom: 2px solid #d82a4e;
}

.signup-form input[type=file],
.contact-form input[type=file] {
	display: none;
}

.signup-form textarea,
.contact-form textarea {
	padding-top: 23px;
	padding-bottom: 15px;
	height: 225px;
}

.signup-form .file-up-btn,
.contact-form .file-up-btn {
	display: block;
	background: #3e3e3e;
	color: #fff;
	max-width: 175px;
	padding: 13px 5px;
	font-size: 14px;
	font-weight: 600;
	margin-bottom: 23px;
	clear: both;
	text-align: center;
	overflow: hidden;
}

.signup-form .site-btn,
.contact-form .site-btn {
	padding: 17px 10px;
}

.featured-courses {
	padding-top: 67px;
}

/*------------------
  Footer section
---------------------*/

.footer-section {
	border-top: 3px solid #d82a4e;
}

.footer-warp {
	max-width: 1559px;
	padding: 0 15px;
	margin: 0 auto;
}

.widget-item {
	width: 20%;
	padding: 0 15px;
}

.widget-item h4 {
	margin-bottom: 30px;
}

.widget-item ul {
	list-style: none;
	padding-top: 0px;
}

.widget-item ul li {
	margin-bottom: 10px;
	font-size: 14px;
	font-weight: 500;
	color: #878787;
}

.widget-item ul li:last-child {
	margin-bottom: 0;
}

.widget-item ul a {
	color: #878787;
}

.widget-item ul.contact-list li {
	margin-bottom: 27px;
	position: relative;
	padding-left: 10px;
}

.widget-item ul.contact-list li:last-child {
	margin-bottom: 0;
}

.widget-item ul.contact-list li:after {
	position: absolute;
	content: "";
	width: 8px;
	height: 8px;
	left: -5px;
	top: 6px;
	border-radius: 50%;
	background: #d82a4e;
}

.footer-newslatter {
	padding-right: 38px;
}

.footer-newslatter input[type=email] {
	height: 57px;
	width: 100%;
	padding: 0 30px;
	margin-bottom: 27px;
	float: left;
	border: none;
	font-size: 14px;
	background: #edf4f6;
}

.footer-newslatter .site-btn {
	min-width: 143px;
	margin-bottom: 20px;
}

.footer-newslatter p {
	margin-bottom: 0;
	font-weight: 500;
	font-style: italic;
}

.footer-bottom {
	overflow: hidden;
	background: #edf4f6;
	padding: 20px 0;
	margin-top: 50px;
}

.footer-menu {
	list-style: none;
	float: right;
}

.footer-menu li {
	display: inline;
}

.footer-menu li:last-child a {
	padding-right: 0;
	margin-right: 0;
}

.footer-menu li:last-child a:after {
	display: none;
}

.footer-menu li a {
	display: inline-block;
	font-size: 14px;
	font-weight: 600;
	color: #474747;
	padding-right: 10px;
	margin-right: 10px;
	position: relative;
}

.footer-menu li a:after {
	position: absolute;
	content: "|";
	right: 0;
	top: 0;
}

.copyright {
	padding-top: 3px;
	float: left;
	font-size: 14px;
	font-weight: 600;
	color: #474747;
}

/*------------------
    Other Pages
---------------------
=======================*/

.page-info-section {
	height: 443px;
}

.site-breadcrumb {
	padding-top: 215px;
}

.site-breadcrumb a,
.site-breadcrumb span {
	display: inline-block;
	margin-right: 10px;
	padding-right: 10px;
	font-weight: 600;
	font-size: 18px;
}

.site-breadcrumb a {
	color: #fff;
	position: relative;
}

.site-breadcrumb a:after {
	position: absolute;
	content: "/";
	right: -6px;
	top: 0;
	color: #fff;
}

.site-breadcrumb span {
	color: #d82a4e;
}

/*------------------
  Single course
---------------------*/

.course-meta-area {
	background: #edf4f6;
	padding: 35px 0;
}

.course-meta-area .course-note {
	display: inline-block;
	background: #d82a4e;
	color: #fff;
	font-size: 14px;
	font-weight: 500;
	padding: 4px 15px;
	margin-bottom: 20px;
}

.course-metas {
	padding-top: 50px;
	margin-bottom: 45px;
	display: table;
}

.course-meta {
	display: table-cell;
	height: 100%;
	border-right: 2px solid #c5ced0;
	padding: 5px 50px;
}

.course-meta:nth-child(1) {
	padding-left: 0;
}

.course-meta:nth-child(4) {
	padding-right: 0;
	border-right: none;
}

.course-meta p {
	margin-bottom: 0;
}

.course-meta .course-author .ca-pic {
	width: 44px;
	height: 44px;
	border-radius: 50%;
	float: left;
}

.course-meta .course-author h6 {
	padding-left: 60px;
}

.course-meta .course-author p {
	padding-left: 60px;
	margin-bottom: 0;
	padding-top: 4px;
	font-weight: 600;
}

.course-meta .course-author p span {
	color: #d82a4e;
}

.course-meta .cm-info h6 {
	margin-bottom: 3px;
}

.course-meta .cm-info p {
	font-weight: 600;
}

.course-meta .rating {
	padding-left: 15px;
	font-size: 12px;
}

.course-meta-area .site-btn {
	min-width: auto;
	font-size: 14px;
}

.course-meta-area .price-btn {
	padding: 20px 30px;
	background: #474747;
	margin-right: 10px;
}

.course-meta-area .buy-btn {
	padding: 20px 40px;
}

.course-preview {
	padding-top: 37px;
	margin-bottom: 55px;
}

.course-list .cl-item {
	margin-bottom: 55px;
}

.course-list .cl-item h4 {
	margin-bottom: 46px;
}

.course-list .cl-item p {
	margin-bottom: 0;
}

.realated-courses {
	background: #edf4f6;
}

.realated-courses .rc-title {
	font-size: 34px;
	margin-bottom: 85px;
}

.realated-courses .course-item .course-info {
	background: #fff;
}

.realated-courses .owl-next {
	position: absolute;
	right: -80px;
	top: 50%;
	margin-top: -27px;
	width: 54px;
	height: 54px;
	border-radius: 50%;
	text-align: center;
	color: #fff;
	font-size: 22px;
	padding-top: 10px;
	background: #d82a4e;
}

/*------------------
  Blog Page
---------------------*/

.blog-page {
	border-bottom: 2px solid #c5ced0;
}

.blog-post {
	margin-bottom: 70px;
}

.blog-post h3 {
	padding-top: 58px;
	margin-bottom: 15px;
}

.blog-post p {
	font-weight: 500;
	font-size: 14px;
	margin-bottom: 25px;
}

.blog-post .blog-metas {
	margin-bottom: 15px;
}

.blog-post .blog-metas .blog-meta {
	display: inline-block;
	padding: 9px 15px;
	position: relative;
	margin-bottom: 5px;
}

.blog-post .blog-metas .blog-meta:last-child:after {
	display: none;
}

.blog-post .blog-metas .blog-meta:after {
	position: absolute;
	content: "";
	width: 2px;
	height: 30px;
	right: 0;
	top: calc(50% - 15px);
	background: #c5ced0;
}

.blog-post .blog-metas .blog-meta.author {
	position: relative;
	padding-left: 50px;
}

.blog-post .blog-metas .blog-meta.author .post-author {
	position: absolute;
	width: 34px;
	height: 34px;
	left: 0;
	top: calc(50% - 17px);
	border-radius: 50%;
	float: left;
	margin-right: 5px;
}

.blog-post .blog-metas .blog-meta a {
	font-size: 14px;
	font-weight: 600;
	color: #878787;
}

.blog-post .site-btn.readmore {
	min-width: 172px;
}

.sb-widget-item {
	margin-bottom: 75px;
}

.sb-widget-item .sb-w-title {
	margin-bottom: 50px;
}

.sb-widget-item .search-widget {
	position: relative;
}

.sb-widget-item .search-widget input {
	width: 100%;
	height: 57px;
	background: #edf4f6;
	font-size: 12px;
	padding: 5px 24px;
	padding-right: 55px;
	border: none;
}

.sb-widget-item .search-widget button {
	position: absolute;
	right: 0;
	top: 0;
	background-color: transparent;
	border: none;
	color: #c5ced0;
	height: 100%;
	width: 50px;
	cursor: pointer;
}

.sb-widget-item ul {
	list-style: none;
	padding-left: 33px;
}

.sb-widget-item ul li {
	display: block;
	margin-bottom: 12px;
}

.sb-widget-item ul li:last-child {
	margin-bottom: 0;
}

.sb-widget-item ul li a {
	color: #9e9e9e;
	display: inline-block;
	font-size: 16px;
	font-weight: 500;
}

.sb-widget-item ul li a:hover {
	color: #d82a4e;
}

.sb-widget-item .tags a {
	font-size: 16px;
	display: inline-block;
	padding: 9px 24px;
	color: #fff;
	background: #d82a4e;
	margin-bottom: 10px;
	margin-right: 6px;
	font-weight: 500;
}

.site-pagination {
	padding-top: 30px;
	padding-bottom: 30px;
}

.site-pagination a,
.site-pagination span {
	font-size: 16px;
	color: #474747;
	font-weight: 600;
}

.site-pagination span.active {
	color: #d82a4e;
}

/*------------------
  Contact Page
---------------------*/

.contact-form-warp {
	background: #d82a4e;
	padding: 45px 47px;
}

.contact-form-warp .section-title {
	padding: 0 15px;
	margin-bottom: 50px;
}

.contact-form-warp .contact-form {
	padding: 69px 40px 47px;
}

.contact-info-area {
	padding-top: 60px;
}

.contact-info-area .section-title {
	margin-bottom: 80px;
}

.contact-info-area .phone-number {
	margin-bottom: 100px;
}

.contact-info-area .phone-number span {
	font-size: 18px;
	font-weight: 700;
	color: #d82a4e;
	display: block;
	margin-bottom: 5px;
}

.contact-info-area .phone-number h2 {
	color: #646464;
	display: inline-block;
	position: relative;
	padding: 0 0 10px;
	padding-right: 12px;
}

.contact-info-area .phone-number h2:after {
	position: absolute;
	content: "";
	width: 100%;
	height: 3px;
	left: 0;
	bottom: 0;
	background: #d82a4e;
}

.contact-info-area .contact-list {
	list-style: none;
	padding-left: 50px;
}

.contact-info-area .contact-list li {
	margin-bottom: 27px;
	position: relative;
	padding-left: 10px;
	font-size: 14px;
	font-weight: 500;
	color: #878787;
}

.contact-info-area .contact-list li:last-child {
	margin-bottom: 0;
}

.contact-info-area .contact-list li:after {
	position: absolute;
	content: "";
	width: 8px;
	height: 8px;
	left: -5px;
	top: 6px;
	border-radius: 50%;
	background: #d82a4e;
}

.contact-info-area .social-links {
	padding-top: 60px;
	padding-left: 50px;
}

.contact-info-area .social-links a {
	font-size: 14px;
	color: #707070;
	margin-right: 30px;
}

.contact-info-area .social-links a:last-child {
	margin-right: 0;
}

#map-canvas {
	height: 484px;
	margin-top: 58px;
	background: #ddd;
}

/*------------------
  Elements Page
---------------------*/

.element {
	margin-bottom: 110px;
}

.element .e-title {
	font-size: 48px;
	color: #474747;
	margin-bottom: 110px;
}

/* Accordion */

.accordion-area .panel {
	margin-bottom: 18px;
}

.accordion-area .panel-link:after {
	content: "+";
	position: absolute;
	font-size: 18px;
	font-weight: 700;
	right: 25px;
	top: 50%;
	color: #000;
	line-height: 16px;
	margin-top: -8px;
	margin-left: -4px;
}

.accordion-area .panel-header .panel-link.active {
	background: #d82a4e;
	color: #fff;
}

.accordion-area .panel-header .panel-link.collapsed {
	background: #f8fafc;
	color: #1d1d1d;
}

.accordion-area .panel-header .panel-link.collapsed:after {
	content: "+";
	color: #1d1d1d;
}

.accordion-area .panel-link.active:after {
	content: "-";
	margin-top: -10px;
	color: #fff;
}

.accordion-area .panel-link {
	text-align: left;
	background-color: transparent;
	border: none;
	position: relative;
	width: 100%;
	padding: 15px 40px;
	padding-left: 30px;
	font-size: 16px;
	font-weight: 600;
	background: #e4edef;
	color: #1f1f1f;
}

.accordion-area .panel-body p {
	margin-bottom: 0;
	padding-top: 35px;
}

.accordion-area .panel-body {
	padding: 0 5px;
}

/* tab */

.tab-element .nav-tabs {
	border-bottom: none;
}

.tab-element .nav-tabs .nav-link {
	border-radius: 0;
	font-size: 16px;
	font-weight: 600;
	color: #1f1f1f;
	border: none;
	padding: 17px 23px;
	margin-right: 8px;
	background: #e4edef;
	margin-bottom: 10px;
}

.tab-element .nav-tabs .nav-item:last-child .nav-link {
	margin-right: 0;
}

.tab-element .nav-tabs .nav-link.active {
	background: #d82a4e;
	color: #fff;
}

.tab-element .nav-tabs .nav-link:focus,
.tab-element .nav-tabs .nav-link:hover {
	border: none;
}

.tab-element .tab-pane p {
	padding-top: 35px;
}

/* Circle progress */

.circle-progress {
	text-align: center;
	display: block;
	position: relative;
	margin-bottom: 30px;
}

.circle-progress canvas {
	-webkit-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	transform: rotate(90deg);
}

.circle-progress .progress-info {
	position: absolute;
	width: 100%;
	top: 50%;
	margin-top: -45px;
}

.circle-progress .progress-info h2 {
	font-weight: 700;
	margin-bottom: 5px;
}

.circle-progress .progress-info p {
	font-size: 14px;
	margin-bottom: 0;
}

.fact-item h2 {
	font-size: 60px;
	color: #d82a4e;
}

.icon-box h5 {
	margin-bottom: 20px;
}

.icon-box h5 span {
	font-size: 60px;
	color: #d82a4e;
	margin-right: 15px;
}

/*------------------
  Responsive
---------------------*/

@media (min-width: 1200px) {
	.container {
		max-width: 1180px;
	}
}

@media (max-width: 1705px) {
	.realated-courses .owl-next {
		position: relative;
		margin: 0 auto;
		left: 0;
	}
}

/* Medium screen : 992px. */

@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.main-menu ul li a {
		margin-left: 38px;
	}
	.header-btn {
		min-width: 160px;
	}
	.hero-text h2 {
		font-size: 55px;
	}
	.intro-newslatter .site-btn {
		min-width: 160px;
	}
	.course-search-form input[type=text],
	.course-search-form input[type=email] {
		width: 34.6%;
	}
	.course-search-form .site-btn {
		min-width: 173px;
	}
	.course-item .course-thumb {
		height: 220px;
	}
	.course-item .course-info .course-text {
		padding: 45px 15px 25px;
	}
	.course-item .course-info .course-author {
		padding: 14px 15px;
	}
	.signup-warp {
		padding: 0 35px;
	}
	.course-meta {
		padding: 5px 20px;
	}
}

/* Tablet :768px. */

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.section-title {
		padding: 0;
	}
	.main-menu ul li a {
		margin-left: 20px;
	}
	.header-btn {
		min-width: 90px;
	}
	.hero-text h2 {
		font-size: 43px;
	}
	.intro-newslatter input[type=text],
	.intro-newslatter input[type=email] {
		width: 50%;
	}
	.intro-newslatter .site-btn {
		min-width: 190px;
	}
	.signup-section .signup-bg {
		display: none;
	}
	.course-search-form {
		text-align: center;
	}
	.course-search-form input[type=text],
	.course-search-form input[type=email] {
		width: 48.1%;
		margin-bottom: 20px;
	}
	.course-search-form input[type=text].last-m,
	.course-search-form input[type=email].last-m {
		margin-right: 0;
	}
	.search-section.ss-other-page .search-warp {
		padding: 60px 30px 30px;
	}
	.search-section.ss-other-page .course-search-form input[type=text],
	.search-section.ss-other-page .course-search-form input[type=email] {
		width: 48.5%;
	}
	.course-item .course-thumb {
		height: 215px;
	}
	.widget-item {
		width: 33.3333%;
		margin-bottom: 50px;
	}
	.course-item.featured-course .course-thumb {
		position: relative;
		width: 100%;
		height: 350px;
	}
	.course-meta-area {
		padding: 35px 20px;
	}
	.course-meta {
		display: inline-block;
		padding: 5px 30px;
		width: calc(50% - 3px);
		margin-bottom: 15px;
	}
	.course-meta:nth-child(2) {
		border-right: none;
	}
}

/* Large Mobile :480px. */

@media only screen and (max-width: 767px) {
	.section-title {
		padding: 0;
	}
	.section-title h2 {
		font-size: 40px;
	}
	.header-btn {
		display: none;
	}
	.nav-switch {
		display: block;
		float: right;
		color: #333;
		font-size: 25px;
		width: 40px;
		height: 40px;
		background: #fff;
		text-align: center;
		padding-top: 2px;
		-webkit-box-shadow: 0 0 13px rgba(0, 0, 0, 0.25);
		box-shadow: 0 0 13px rgba(0, 0, 0, 0.25);
	}
	.main-menu {
		background: #fff;
		margin-top: 22px;
		position: absolute;
		width: 100%;
		left: 0;
		top: 100%;
		display: none;
		z-index: 999;
	}
	.main-menu ul li {
		display: block;
	}
	.main-menu ul li a {
		margin-left: 0;
		padding: 17px 25px;
		border-bottom: 1px solid #e8e8e8;
		display: block;
		color: #474747;
	}
	.hero-text {
		margin-bottom: 50px;
	}
	.hero-text h2 {
		font-size: 40px;
	}
	.intro-newslatter {
		text-align: center;
	}
	.intro-newslatter input[type=text],
	.intro-newslatter input[type=email] {
		margin-bottom: 20px;
		width: 47.6%;
	}
	.intro-newslatter input[type=text].last-s,
	.intro-newslatter input[type=email].last-s {
		margin-right: 0;
	}
	.search-warp {
		padding: 40px 30px 30px;
	}
	.course-search-form input[type=text],
	.course-search-form input[type=email] {
		width: 100%;
		margin-bottom: 20px;
		margin-right: 0;
	}
	.signup-warp {
		padding: 0 60px;
	}
	.signup-section .signup-bg {
		display: none;
	}
	.widget-item {
		width: 50%;
		margin-bottom: 50px;
	}
	.search-section.ss-other-page .search-warp {
		padding: 30px;
	}
	.search-section.ss-other-page .section-title h2 {
		background: #d82a4e;
	}
	.course-item.featured-course .course-thumb {
		position: relative;
		width: 100%;
		height: 350px;
	}
	.course-meta-area {
		padding: 35px 20px;
	}
	.course-meta {
		display: inline-block;
		padding: 5px 0;
		width: 100%;
		margin-bottom: 15px;
		border-right: none;
	}
}

/* Medium Mobile :480px. */

@media only screen and (max-width: 576px) {
	.hero-section {
		height: auto;
	}
	.intro-newslatter {
		padding-bottom: 100px;
	}
	.intro-newslatter input[type=text],
	.intro-newslatter input[type=email] {
		margin-bottom: 20px;
		width: 100%;
		margin-right: 0;
	}
	.course-search-form .site-btn {
		min-width: auto;
		padding: 16px 20px;
	}
	.signup-warp {
		padding: 0;
	}
	.signup-form,
	.contact-form {
		padding: 30px;
	}
	.course-filter li {
		font-size: 14px;
	}
	.widget-item {
		width: 100%;
		margin-bottom: 50px;
	}
	.course-meta-area .price-btn {
		margin-bottom: 20px;
	}
}

/* Small Mobile :320px. */

@media only screen and (max-width: 479px) {
	.section-title h2 {
		font-size: 30px;
	}
	.signup-form,
	.contact-form {
		padding: 20px;
	}
	.search-warp {
		padding: 40px 20px 30px;
	}
	.course-search-form {
		padding: 44px 20px;
	}
	.contact-form-warp {
		padding: 40px 15px;
	}
	.contact-form-warp .contact-form {
		padding: 50px 15px 40px;
	}
	.signup-form .site-btn,
	.contact-form .site-btn {
		min-width: auto;
		padding: 17px 20px;
	}
	.contact-info-area .phone-number {
		margin-bottom: 50px;
	}
	.contact-info-area .phone-number h2 {
		font-size: 24px;
	}
	.contact-info-area .contact-list {
		padding-left: 8px;
	}
	.contact-info-area .social-links {
		padding-left: 0;
	}
	.contact-info-area .social-links a {
		margin-right: 10px;
	}
}

table{
	font-family: sans-serif !important;
}
.card-header {
    background-color: rgba(0, 89, 255, 1) !important;
    color: white;
}
.table .thead-dark th {
    color: #fff !important;
    background-color: blue !important;
    border-color: #00000025 !important;
}
.fa-pencil-square-o{
    color: blue;
}
.fa-trash{
    color: red;
}
.fa-trash-o{
    color: red;
}